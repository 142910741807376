<template>
    <div class="meo-login">
        <head>
            <meta name="description" content="Sign up for free to access exclusive free content and sessions." />
        </head>

        <v-container px-5 pb-6 v-if="!signupSent && !error" class="dark--text">
            <form @submit.prevent="submit">
                <v-row>
                    <v-col class="shrink" cols="12">
                        <h2 class="text-no-wrap mt-6 mb-3">Sign Up</h2>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="firstName"
                            v-model="firstName"
                            :disabled="loading"
                            required
                            label="First name"
                            :rules="[rules.required]"
                            class="pt-0 mt-0"
                            color="dark"
                            outlined
                            placeholder="Enter your first name..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="lastName"
                            v-model="lastName"
                            :disabled="loading"
                            required
                            label="Last name"
                            :rules="[rules.required]"
                            class="pt-0 mt-0"
                            color="dark"
                            outlined
                            placeholder="Enter your last name..."
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            ref="email"
                            v-model="email"
                            type="email"
                            :disabled="loading"
                            label="Email Address"
                            required
                            :rules="[rules.required, rules.email]"
                            class="pt-0 mt-0"
                            outlined
                            color="dark"
                            placeholder="Enter your email..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="country"
                            v-model="country"
                            :disabled="loading"
                            label="Country"
                            :items="countries"
                            item-text="name"
                            item-value="code"
                            class="pt-0 mt-0"
                            outlined
                            color="dark"
                            placeholder="Select your country..."
                            return-object
                        >
                            <template v-slot:item="{ item }">
                                <v-icon class="mr-4">{{ item.emoji }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <template v-slot:selection="{ item }">
                                <v-icon class="mr-4">{{ item.emoji }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="password"
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            :disabled="loading"
                            label="Password"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                            required
                            :rules="[rules.required, rules.minLength6]"
                            class="pt-0 mt-0"
                            outlined
                            color="dark"
                            placeholder="Enter a password..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            :type="showPassword ? 'text' : 'password'"
                            v-model="confirmPassword"
                            :disabled="loading"
                            label="Confirm password"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                            required
                            :rules="[rules.isMatch]"
                            class="pt-0 mt-0"
                            color="dark"
                            outlined
                            placeholder="Confirm password..."
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block x-large color="white" type="submit" :loading="loading">
                            <span class="dark--text">Sign Up</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.login' }" small text color="dark" :disabled="loading">
                            <span class="text-capitalize font-weight-regular">Already have an account?</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>

        <v-container px-5 pb-6 v-else-if="error" class="dark--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Sign Up</h2>
                    <p class="text-muted text-center dark--text">An error occured while trying to sign you up. Please try again.</p>
                    <p class="text-center dark--text mb-0">
                        <b>{{ errorText }}</b>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large color="white" @click="error = false">
                        Back
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block :to="{ name: 'auth.login' }" small text color="dark">
                        <span class="text-capitalize font-weight-regular">Already have an account?</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container px-5 pb-6 v-else class="primary--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Sign Up</h2>
                    <p class="text-muted text-center grey--text">You have signed up successfully!</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block x-large rounded dark color="dark" :to="{ name: 'auth.login' }">
                        Back to Log in
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";
import Countries from "@/modules/countries";
import Contact from "@/modules/meo-module/contact";

export default {
    mixins: [UserMixin],
    meta: {
        titleTemplate: "Sign Up | %s",
    },
    mounted() {
        if (!this.firstName || !this.firstName.length) {
            return this.$refs.firstName.focus();
        }

        if (!this.lastName || !this.lastName.length) {
            return this.$refs.lastName.focus();
        }

        if (!this.email || !this.email.length) {
            return this.$refs.email.focus();
        }

        if (!this.password || !this.password.length) {
            return this.$refs.password.focus();
        }
    },
    data() {
        return {
            errorText: "happy",
            loading: false,
            signupSent: false,
            error: false,
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            country: {},
            showPassword: false,
            rules: {
                required: (value) => !!value || "This field is required",
                minLength3: (value) => value.length >= 3 || "Min 3 characters",
                minLength4: (value) => value.length >= 4 || "Min 4 characters",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },
    methods: {
        submit() {
            var self = this;

            //Check if there is an application
            var applicationContext = self.application && self.application.authenticationStyle == "application";

            self.loading = true;

            console.log("Sign up to application");
            self.$fluro.auth
                .signup(
                    {
                        username: self.email,
                        firstName: self.firstName,
                        lastName: self.lastName,
                        password: self.password,
                        confirmPassword: self.confirmPassword,
                    },
                    {
                        bypassInterceptor: true,
                        application: applicationContext, //Sign up to the application rather than Fluro itself
                    }
                )
                .then(signupSuccess, signupFail);

            //////////////////////////////////

            async function signupSuccess(res) {
                var user = res.data;
                console.log("Signup success", user);
                // self.$store.commit('user', res.data);

                let contact = user.contacts;

                if (contact && contact.length) {
                    let contactId = contact[0];

                    let payload = {
                        data: {
                            country: self.country,
                        },
                    };

                    let updated = await Contact.update(contactId, payload).then(({ data }) => data);
                } else {
                    console.log("Could not find contact");
                }

                if (self.$route.query && self.$route.query.module) {
                    // Go straight to purchasing a module
                    self.$router.push({ name: "purchase", params: { id: self.$route.query.module } });
                } else {
                    self.$router.push({ name: "modules" });
                }

                self.loading = false;
            }

            //////////////////////////////////

            function signupFail(err) {
                var message = self.$fluro.utils.errorMessage(err);
                self.error = true;
                self.errorText = message;
                console.log("ERROR!", message, err);
                self.loading = false;
                // self.$store.dispatch('logout');
            }
        },
    },
    computed: {
        email: {
            set(email) {
                this.$store.commit("email", email);
            },
            get(email) {
                return this.$store.getters.email;
            },
        },
        countries() {
            return Object.keys(Countries).map((key) => {
                return {
                    name: Countries[key].name,
                    code: key,
                    emoji: Countries[key].emoji,
                    unicode: Countries[key].unicode,
                    image: Countries[key].image,
                };
            });
        },
    },
};
</script>
